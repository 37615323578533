import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./global.css";
import { SpeedInsights } from "@vercel/speed-insights/react";
import NavBar from "./common/NavBar";
import Home from "./view/Home";
import About from "./view/About";
import Career from "./view/Career";
import Contact from "./view/Contact";
import Configuratore from "./view/Configuratore";
import Footer from "./common/Footer";
import AOS from "aos";
import ReactGA from 'react-ga4';
import { initGA, logPageView } from './types/ga'; // Adjust the import path accordingly
import GAListener from './components/GAListener'; // Adjust the import path accordingly



import { Analytics } from "@vercel/analytics/react";

import { useEffect } from "react";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Product_SARA from "./view/Product_SARA";
import ScrollToAnchor from "./ScrollToAnchor";
import NewsPage from "./view/NewsPage";
import NewsDetail from "./view/NewsDetail";
import ConfiguratoreUI from "./common/configuratore/ConfiguratoreUI";
function Background() {
  const location = useLocation();
  

  return location.pathname !== "/news" &&
    !location.pathname.startsWith("/news/") && location.pathname !== "/products/sara" && !location.pathname.startsWith("/products/sara") ? (
    <div className="h-[92rem] absolute top-0 left-0 w-full bg-[url('/public/bg.svg')] bg-cover bg-bottom opacity-75  pointer-events-none mt-20 "></div>
  ) : (
    <div></div>
  );
}
function App() {
  AOS.init();

  useEffect(() => {
      initGA(process.env.ANALYTIC); // Replace with your actual GA tracking ID

  }, []);

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                "We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified in the cookie policy. Use the “Accept” button to consent. Use the “Reject” button or close this notice to continue without accepting.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage Individual preferences",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Somebody said ... cookies?",
                  description: "I want one!",
                },
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a href="/contact">contact us</a>',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return (
    <div className="bg-black overflow-x-hidden">
      <BrowserRouter>
        <NavBar />
        <div className="bg-black">
          <Background />
          <ScrollToAnchor />
          <GAListener>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="home" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="products/sara" element={<Product_SARA />} />
            <Route path="contact" element={<Contact />} />
            <Route path="careers" element={<Career />} />
            <Route path="news/:name/:id" element={<NewsDetail />} />
            <Route path="configuratore" element={<ConfiguratoreUI />} />
            <Route path="news" element={<NewsPage />} />
            {/*<Route path="configuratore" element={<Configuratore />} />*/}
          </Routes>
          </GAListener>
        </div>
        <Footer />
      </BrowserRouter>
      <Analytics />
      <SpeedInsights />
    </div>
  );
}
export default App;
